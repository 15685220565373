$( document ).ready(function() {
    $('.clipboardCopy').click(function() {
        navigator.clipboard.writeText($(this).data('text'));

        Vue.$toast.open({
            message: "Skopiowano do schowka",
            type: 'info',
        });
    });
});
